
import React from 'react';


import {connect} from 'react-redux';
import html2pdf from 'html2pdf.js';
import LoftyDropdownComponent from '../../components/loftydropdowncomponent';
import LoftyCountDown from '../../components/loftycountdown';
import LoftyCartCarousel from '../../components/loftycartcarousel';
import LoftyEditorJsReadOnly from '../../components/LoftyEditorJsReadOnly';
import LoftyEditorJs from '../../components/LoftyEditorJs';

import { addLoftyToken, deleteLoftytoken } from '../../actions/auth.actions';


import './paginamuypronto.scss';






const Ppaginamuypronto = ({
  match,
  history,
  loftytoken,
  loftyuser,
  addLoftyUserApp,
  deleteLoftytokenApp,
  addLoftyTokenApp,
  
}) => {





  
  
  
  

  
  return (
    <div id="id66282499e5bd9e8c7add2cf9" >

      <div id="i3cg" className="">
      
      <div id="i2ow" className="">
      
      <img
        className=""
        id="ihhgn"
        src="https://assetsprojects.s3.amazonaws.com/eewk2ilvcw2qxy.png"
        alt="undefined"
      />
      
      </div>
      
      <div id="i01ugh" className="">
      
      <p className="" id="ibmh3">
        <span>Muy Pronto</span>
      </p>
      
      </div>
      
      <div id="il0ii" className="">
      
        <a
          target=""
          className="botonprincipal botoncontacto "
          id="i2l21"
          href="mailto:contact@imacempro.com"
        >
          
    <div id="iqqiu" className="">
    <span>Contáctame</span>
    </div>
    
        </a>
        
      </div>
      
      </div>
      
</div>
  );
}

const mapStateToProps = (state) => state.auth;

const mapDispatchToProps = (dispatch) => ({
 addLoftyTokenApp: (data) => dispatch(addLoftyToken(data)),
 deleteLoftytokenApp: () => dispatch(deleteLoftytoken()),
});


export default connect(mapStateToProps, mapDispatchToProps)(Ppaginamuypronto);
  