
import axios from '../config/axios';

export const url = '/api/contacto'


export const createcontacto = ({
  token,
	telephone,
	message,
	name,
	email,
	subject,
	loftyFile,

}) => new Promise((resolve, reject) => {
  if (token && telephone && message && name && email && subject ) {

    axios.post(`${url}//create/basic/660f2091ff55bb6ed4354e61`, {
		telephone,
		message,
		name,
		email,
		subject,

    }, {
      headers: {
        
        Authorization: token,
      }
    }).then((res) => {
      const {data} = res;
      if (data.status === 'success') {
        resolve(data);
      } else {
        reject(data);
      }
    }).catch(error => reject(error));
  } else if (!telephone) {
		reject({
			status: 'error',
			info: 'telephone no esta definido',
		});
	}  else if (!message) {
		reject({
			status: 'error',
			info: 'message no esta definido',
		});
	}  else if (!name) {
		reject({
			status: 'error',
			info: 'name no esta definido',
		});
	}  else if (!email) {
		reject({
			status: 'error',
			info: 'email no esta definido',
		});
	}  else if (!subject) {
		reject({
			status: 'error',
			info: 'subject no esta definido',
		});
	}  else {
    reject({
      status: 'error',
      info: 'Token no definido'
    });
  }
});
      

