
import React, {useEffect, useState} from 'react';

function LoftyCartCarousel ({
  list, idMain, classMain,
  cartCarouselListSection,
  idcartCarouselListSection,
  classcartCarouselListSection,
  showCartItemImage,
  idshowCartItemImage,
  classshowCartItemImage,
  cartItemInfoRender,
  idcartItemInfoRender,
  classcartItemInfoRender,
  handleShowInfo,
}) {
    const [variableStateInfo, setVariableStateInfo] = useState([]);
    const [hasMoreThanFive, setHasMoreThanFive] = useState(false);
    const [currentRendered, setCurrentRendered] = useState([]);
    const [startIndex, setStartIndex] = useState(0);
    const [lowerLimit, setLowerLimit] = useState(0);
    const [upperLimit, setUpperLimit] = useState(0);
    const [currentItem, setCurrentItem] = useState([]);

    useEffect(() => {
      let currentStateForRender = list;
      if (list?.length > 5) {
        setHasMoreThanFive(true);
        currentStateForRender = list.slice(startIndex, 5);
        setLowerLimit(0);
        setUpperLimit(5);
      }
      setCurrentItem(list[startIndex]);
      setCurrentRendered(currentStateForRender);
      setVariableStateInfo(list);
    }, []);
    function nextItemCarousel() {
    if (startIndex + 1 >= variableStateInfo.length) {
      return null;
    }
    setCurrentItem(variableStateInfo[startIndex + 1]);
    if (hasMoreThanFive && startIndex + 1 >= upperLimit) {
      const updateCurrentRender = variableStateInfo.slice(lowerLimit + 5, upperLimit + 5);
      setLowerLimit(lowerLimit + 5);
      setUpperLimit(upperLimit + 5);
      setCurrentRendered(updateCurrentRender);
    }
    setStartIndex(startIndex + 1);
  }

  function previousItemCarousel() {
    if (startIndex - 1 < 0) {
      return null;
    }
    setCurrentItem(variableStateInfo[startIndex - 1]);
    if (hasMoreThanFive && startIndex - 1 < lowerLimit) {
      const updateCurrentRender = variableStateInfo.slice(lowerLimit - 5, upperLimit - 5);
      setLowerLimit(lowerLimit - 5);
      setUpperLimit(upperLimit - 5);
      setCurrentRendered(updateCurrentRender);
    }
    setStartIndex(startIndex - 1);
  }
  return (
    <div id={idMain} className={classMain}>
      {
        cartCarouselListSection && (
          <div id={idcartCarouselListSection} className={classcartCarouselListSection}>
            {currentRendered.map((item) => (
              <div
                style={{opacity: item?.cartitem?._id === currentItem?.cartitem?._id ? 1 : 0.2}}
              >
                <img src={item?.cartitem?.loftyUrl} alt="img" style={{height: '100px', objectFit: 'contain'}} />
              </div>
            ))}
          </div>
        )
      }
      {
        showCartItemImage && (
          <div id={idshowCartItemImage} className={classshowCartItemImage}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                width: '100%',
              }}
            >
              <img src={currentItem?.cartitem?.loftyUrl} alt="img" style={{height: '500px', objectFit: 'contain'}} />
            </div>
            <div style={{
              display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', alignItems: 'center', width: '100%',
            }}
            >
              <div style={{fontSize: '32px', cursor: 'pointer'}} role="button" tabIndex={0} onClick={previousItemCarousel}>
                &#8678;
              </div>
              <div style={{fontSize: '32px', cursor: 'pointer'}} role="button" tabIndex={0} onClick={nextItemCarousel}>
                &#8680;
              </div>
            </div>
          </div>
        )
      }
      {
        cartItemInfoRender && (
          <div id={idcartItemInfoRender} className={classcartItemInfoRender}>
            {handleShowInfo(currentItem)}
          </div>
        )
      }
    </div>
  )
}

export default LoftyCartCarousel;

    