
import React, {useEffect, useState} from 'react';


import {connect} from 'react-redux';
import html2pdf from 'html2pdf.js';
import LoftyDropdownComponent from '../../components/loftydropdowncomponent';
import LoftyCountDown from '../../components/loftycountdown';
import LoftyCartCarousel from '../../components/loftycartcarousel';
import LoftyEditorJsReadOnly from '../../components/LoftyEditorJsReadOnly';
import LoftyEditorJs from '../../components/LoftyEditorJs';

import { addLoftyToken, deleteLoftytoken } from '../../actions/auth.actions';

import {createcontacto } from '../../api/contacto.api'

import './servicios2.scss';


// Hamburger
function loftyInitHam() {
  try {
  // eslint-disable-next-line no-restricted-globals
    window.addEventListener('click', (event) => {
      let originPointLeft = '';
      const comp = event.target.className;
      if (comp.includes('menu__btn') || comp.includes('span_middle') || comp.includes('span_before') || comp.includes('span_after')) {
        const res = Array.from(document.getElementsByClassName('menu__box'));
        const spanData = Array.from(document.getElementsByClassName('menu__btn'));
        const closeBtn = Array.from(document.getElementsByClassName('menu__close__btn'));
        spanData.forEach((item) => {
          originPointLeft = item.style.left;
        });
        res.forEach((item) => {
          item.style.left = '0px';
        });
        spanData.forEach((item) => {
          item.style.left = '-300px';
        });
        closeBtn.forEach((item) => {
          item.style.left = '10px';
        });
      } else {
        if (comp === 'menu__box') {
          return;
        }
        if (comp === 'linkshref') {
          return;
        }
        if (comp === 'menu__item') {
          return;
        }
        const res = Array.from(document.getElementsByClassName('menu__box'));
        const spanData = Array.from(document.getElementsByClassName('menu__btn'));
        const closeBtn = Array.from(document.getElementsByClassName('menu__close__btn'));
        res.forEach((item) => {
          item.style.left = '-300px';
        });
        spanData.forEach((item) => {
          item.style.left = originPointLeft;
        });
        closeBtn.forEach((item) => {
          item.style.left = '-100%';
        });
      }
    });
  } catch (error) {
    // console.log(error);
  }
}    
    




const Pservicios2 = ({
  match,
  history,
  loftytoken,
  loftyuser,
  addLoftyUserApp,
  deleteLoftytokenApp,
  addLoftyTokenApp,
  
}) => {


useEffect(() => {
		const loftyHandleData = async () => {
		}
		loftyHandleData();
	}, []);



  
  setTimeout(() => { loftyInitHam(); }, 2000);

  
  

  
  return (
    <div id="id664e226c914da0d427fd590c" >

      <div id="i3cg" className="">
      
      <div id="iw4tpx" className="">
      
        <a
          target=""
          className=""
          id="i7xb8r"
          href="/"
        >
          
      <img
        className=""
        id="iw2km3"
        src="https://assetsprojects.s3.amazonaws.com/406racilu44h9vu.png"
        alt="undefined"
      />
      
      <img
        className=""
        id="ifnuqh"
        src="https://assetsprojects.s3.amazonaws.com/eewk5ilwi1t2ck.png"
        alt="undefined"
      />
      
        </a>
        
      <div id="i188q6" className="">
      
      <div id="" className="contentwrapperanimated ">
      
      <div id="" className="container_animated ">
      
      <label id="" className="menu__btn ">
      
      <span className="span_before " id="">
        
      </span>
      
      <span className="span_middle " id="">
        
      </span>
      
      <span className="span_after " id="">
        
      </span>
      
      </label>
      
      <label id="" className="menu__close__btn ">
      
      <span className="span__close__left " id="">
        
      </span>
      
      <span className="span__close__right " id="">
        
      </span>
      
      </label>
      
      <ul id="" className="menu__box ">
        
      <li className="linkshref " id="">
        
      <a
        target=""
        className="menu__item "
        id=""
        href="/"
        type=""
      >
        <span>Inicio</span>
      </a>
      
      </li>
      
      <li className="linkshref " id="">
        
      <a
        target=""
        className="menu__item "
        id=""
        href="#"
        type=""
      >
        <span>Servicios</span>
      </a>
      
      </li>
      
      <li className="linkshref " id="">
        
      <a
        target=""
        className="menu__item "
        id=""
        href="/eventos"
        type=""
      >
        <span>Eventos</span>
      </a>
      
      </li>
      
      <li className="linkshref " id="">
        
      <a
        target=""
        className="menu__item "
        id=""
        href="/blog"
        type=""
      >
        <span>Blog</span>
      </a>
      
      </li>
      
      <li className="linkshref " id="">
        
      <a
        target=""
        className="menu__item "
        id=""
        href="/contacto"
        type=""
      >
        <span>Contacto</span>
      </a>
      
      <a
        target=""
        className="menu__item "
        id=""
        href="/en/services"
        type=""
      >
        <span>Inglés</span>
      </a>
      
      </li>
      
      </ul>
      
      </div>
      
      </div>
      
      </div>
      
      <div id="i282zh" className="">
      
        <a
          target=""
          className="link-nabvar "
          id="igkctr"
          href={`/`}
        >
          
    <div id="im43t5" className="">
    <span>INICIO</span>
    </div>
    
        </a>
        
        <a
          target=""
          className="link-nabvar "
          id="iajvrv"
          href={`/servicios`}
        >
          
    <div id="i4h4c3" className="">
    <span>SERVICIOS</span>
    </div>
    
        </a>
        
        <a
          target=""
          className="link-nabvar "
          id="iibluu"
          href={`/eventos`}
        >
          
    <div id="io4byk" className="">
    <span>EVENTOS</span>
    </div>
    
        </a>
        
        <a
          target=""
          className="link-nabvar "
          id="iugyy5"
          href={`/blog`}
        >
          
    <div id="id5l3e" className="">
    <span>BLOG</span>
    </div>
    
        </a>
        
      </div>
      
      <div id="iucead" className="">
      
        <a
          target=""
          className="boton-lineal-azul "
          id="inoz1l"
          href={`/contacto`}
        >
          
    <div id="ii95xa" className="">
    <span>CONTACTAR</span>
    </div>
    
        </a>
        
        <a
          target=""
          className=""
          id="imdyke"
          href="#"
        >
          
      <img
        className=""
        id="idnkr7"
        src="https://assetsprojects.s3.amazonaws.com/eewk5ilwaojs7b.png"
        alt="undefined"
      />
      
        </a>
        
        <a
          target=""
          className=""
          id="io94k5"
          href="#"
        >
          
      <img
        className=""
        id="ixrczl"
        src="https://assetsprojects.s3.amazonaws.com/eewk5ilwaokuhj.png"
        alt="undefined"
      />
      
        </a>
        
      </div>
      
      </div>
      
      <div id="iz999t" className="">
      
      <div id="iwj18p" className="">
      
      <div
        id="izpzwf"
        className=""
        data-aos="fade-down"
        data-aos-duration="1000"
      >
      
      <span className="" id="i8qky5">
        <span>SERVICIOS</span>
      </span>
      
      </div>
      
      </div>
      
      </div>
      
      <div id="izg5" className="">
      
      <div id="ijcy9b" className="">
      
      <div id="i0jvoi" className="">
      
      <div id="ipfeog" className="tarjeta ">
      
      <div id="it0t47" className="">
      
      <img
        className="img-servicio "
        id="i1lbie"
        src="https://assetsprojects.s3.amazonaws.com/eewk2ilvlhdqqc.png"
        alt="undefined"
      />
      
      </div>
      
      <div id="ixq5ql" className="">
      
      <p className="titulo-tarjeta " id="iz2b8p">
        <span>Asesorías en Comunicación e Imagen</span>
      </p>
      
      <p className="descripcion-tarjeta " id="iyw3al">
        <span>Optimizamos su comunicación e imagen corporativa para un posicionamiento destacado</span>
      <br className="" id="" />
      
      </p>
      
      </div>
      
      </div>
      
      <div id="i3ho6u" className="tarjeta ">
      
      <div id="iuv1r7" className="">
      
      <img
        className="img-servicio "
        id="i4q4uj"
        src="https://assetsprojects.s3.amazonaws.com/eewk2ilvlhnmes.png"
        alt="undefined"
      />
      
      </div>
      
      <div id="ihnf3g" className="">
      
      <p className="titulo-tarjeta " id="idd801">
        <span>Embajadores de Marca y Moderación</span>
      <br className="" id="" />
      
      </p>
      
      <p className="descripcion-tarjeta " id="imr5qh">
        <span>Creación y promoción de embajadores que comuniquen eficazmente su visión y valores</span>
      <br className="" id="" />
      
      </p>
      
      </div>
      
      </div>
      
      <div id="itgpjo" className="tarjeta ">
      
      <div id="i3a3eo" className="">
      
      <img
        className="img-servicio "
        id="igpqot"
        src="https://assetsprojects.s3.amazonaws.com/eewk2ilvlhj8bh.png"
        alt="undefined"
      />
      
      </div>
      
      <div id="izejpj" className="">
      
      <p className="titulo-tarjeta " id="i040xk">
        <span>Capacitación Especializada</span>
      </p>
      
      <p className="descripcion-tarjeta " id="ikq2x5">
        <span>Talleres y cursos en oratoria, servicio al cliente, protocolo, imagen televisiva, trabajo en equipo, motivación a logros, etc.</span>
      <br className="" id="" />
      
      </p>
      
      </div>
      
      </div>
      
      </div>
      
      <div id="it7uh8" className="">
      
      <div id="ihwcbh" className="tarjeta ">
      
      <div id="i1t4vl" className="">
      
      <img
        className="img-servicio "
        id="iwpbnl"
        src="https://assetsprojects.s3.amazonaws.com/eewk2ilvlhi1t7.png"
        alt="undefined"
      />
      
      </div>
      
      <div id="isp0cp" className="">
      
      <p className="titulo-tarjeta " id="irjtrk">
        <span>Manejo de Crisis</span>
      </p>
      
      <p className="descripcion-tarjeta " id="iqumge">
        <span>Desarrollamos estrategias proactivas y reactivas para gestionar crisis, protegiendo su imagen y reputación</span>
      </p>
      
      </div>
      
      </div>
      
      <div id="ii5gkl" className="tarjeta ">
      
      <div id="i7gat2" className="">
      
      <img
        className="img-servicio "
        id="iksda4"
        src="https://assetsprojects.s3.amazonaws.com/eewk2ilvlhpffl.png"
        alt="undefined"
      />
      
      </div>
      
      <div id="ihyrmh" className="">
      
      <p className="titulo-tarjeta " id="im1yqy">
        <span>Desarrollo de Marca Personal y Corporativa </span>
      </p>
      
      <p className="descripcion-tarjeta " id="ilotu9">
        <span>Construimos y reforzamos su marca para asegurar una identidad única y de valor agregado</span>
      <br className="" id="" />
      
      </p>
      
      </div>
      
      </div>
      
      </div>
      
      </div>
      
      </div>
      
      <div id="ipvc3" className="">
      
      <div
        id="io444i"
        className=""
        data-aos="fade-up"
        data-aos-duration="undefined"
      >
      
      <div id="imdp4j" className="titulo-seccion ">
      
      <p className="titulo-seccion-imacem " id="iffnpy">
        <span>QUIENES SOMOS</span>
      </p>
      
      </div>
      
      <div id="ialofi" className="">
      
      <div id="ij0bfi" className="">
      
      <p className="" id="ivr55n">
        <span>IMACEM Consultores S.A., su socio estratégico en el mundo de la comunicación, imagen y manejo de crisis. Especializados en ofrecer servicios integrales que abarcan desde la comunicación e imagen, moderación y desarrollo de marcas, hasta la capacitación experta en oratoria, servicio al cliente, protocolo, motivación a logros, trabajo en equipo, etc. Nos dedicamos a potenciar el crecimiento personal y organizacional a través de soluciones innovadoras, personalizadas y efectivas para enfrentar cualquier desafío.</span>
      <br className="" id="" />
      
      </p>
      
      </div>
      
      <img
        className=""
        id="i2pjh8"
        src="https://assetsprojects.s3.amazonaws.com/eewk5ilxw3915k.jpg"
        alt="undefined"
      />
      
      </div>
      
      </div>
      
      </div>
      
      <div id="i2gqb8" className="">
      
      <div
        id="i9rig9"
        className=""
        data-aos="fade-up"
        data-aos-duration="undefined"
      >
      
      <div id="i6ouem" className="titulo-seccion ">
      
      <p className="titulo-seccion-imacem " id="irpu25">
        <span>CONTÁCTANOS</span>
      </p>
      
      </div>
      
      <div id="io9p7m" className="">
      
      <img
        className=""
        id="i6vyni"
        src="https://assetsprojects.s3.amazonaws.com/eewk5ilwmk3g5b.png"
        alt="undefined"
      />
      
      <div id="ieloqw" className="">
      
        <div className="" id="iu0znj">
          
      <form
        id="ixlms3"
        className=""
        onSubmit={async (e) => {
				e.preventDefault();
				try {
						const lofty_response = await createcontacto({
						name: e.target.name.value,
						telephone: e.target.telephone.value,
						message: e.target.message.value,
						email: e.target.email.value,
						subject: e.target.subject.value,
					});
					alert("Se envio el contacto de manera exitosa");
		} catch (error) {
					console.log(error);
				}
			}}
		
      >
        
      <div id="ia2nce" className="">
      
      <br className="" id="" />
      
      <input
        name="name"
        type="text"
        placeholder="Nombre"
        className="input "
        id="ibtk22"
      />
      
      </div>
      
      <div id="ihut6j" className="">
      
      <br className="" id="" />
      
      <input
        name="telephone"
        type="text"
        placeholder="Telefono"
        className="input "
        id="iubdny"
      />
      
      </div>
      
      <div id="iykl9v" className="">
      
      <br className="" id="" />
      
      <input
        name="message"
        type="text"
        placeholder="Mensaje"
        className="input "
        id="imzhmq"
      />
      
      </div>
      
      <div id="irx4qf" className="">
      
      <br className="" id="" />
      
      <input
        name="email"
        type="text"
        placeholder="Correo"
        className="input "
        id="i7so1x"
      />
      
      </div>
      
      <div id="ijc4af" className="">
      
      <br className="" id="" />
      
      <input
        name="subject"
        type="text"
        placeholder="Tema"
        className="input "
        id="ivpis3"
      />
      
      </div>
      
      <div id="i0alkp" className="">
      
      <button
        type="submit"
        name=""
        id=""
        className="boton-lineal-naranja "
        
      >
      
      <div id="" className="">
      <span>ENVIAR</span>
      </div>
      
      </button>
      
      </div>
      
      </form>
      
        </div>
        
      </div>
      
      </div>
      
      </div>
      
      </div>
      
      <div id="iat5x" className="">
      
      <div id="ighxck" className="">
      
      <img
        className=""
        id="inqivl"
        src="https://assetsprojects.s3.amazonaws.com/406racilu44h9vu.png"
        alt="undefined"
      />
      
      </div>
      
      <div id="ipa2qp" className="">
      
      <div id="ise2r9" className="">
      
      <div id="i0qriz" className="">
      
      <p className="" id="i14ubl">
        <span>Oficina</span>
      </p>
      
      <div id="i8hlod" className="">
      
      <p className="" id="icpwci">
        <span>Honduras - Tegucigalpa MDC</span>
      </p>
      
      <p className="" id="i0vnkg">
        
      <a
        target=""
        className=""
        id="ijgdod"
        href="mailto:contact@imacempro.com"
        type=""
      >
        <span>contact@imacempro.com</span>
      </a>
      
      </p>
      
      </div>
      
      <p className="" id="i7jvdl">
        <span>+504 9982-3483</span>
      </p>
      
      </div>
      
      <div id="ia2by2" className="">
      
      <p className="" id="i5kwyk">
        <span>Links</span>
      </p>
      
      <div id="ijr81i" className="">
      
      <p className="" id="izt1ml">
        
      <a
        target=""
        className="vinculo-negro "
        id="i0dsjx"
        href="/"
        type=""
      >
        <span>Inicio</span>
      </a>
      
      </p>
      
      <p className="" id="ip0pg2">
        
      <a
        target=""
        className="vinculo-negro "
        id="i0qg4t"
        href="/eventos"
        type=""
      >
        <span>Eventos</span>
      </a>
      
      </p>
      
      <p className="" id="i4aa3m">
        
      <a
        target=""
        className="vinculo-negro "
        id="ie752i"
        href="/blog"
        type=""
      >
        <span>Blog</span>
      </a>
      
      </p>
      
      <p className="" id="iiv8pp">
        
      <a
        target=""
        className="vinculo-negro "
        id="ixb9qh"
        href="/contacto"
        type=""
      >
        <span>Contacto</span>
      </a>
      
      </p>
      
      </div>
      
      </div>
      
      <div id="ieb919" className="">
      
      <p className="" id="ic9ies">
        <span>Sociales</span>
      </p>
      
      <div id="idmvel" className="">
      
      <p className="" id="icjl6l">
        
      <a
        target="_blank"
        className=""
        id="i2ig2l"
        href="https://www.linkedin.com/company/imacem-consultores-s-a/"
        type=""
      >
        <span>Linkedin</span>
      </a>
      
      </p>
      
      <p className="" id="io7ssq">
        
      <a
        target="_blank"
        className=""
        id="iikn9h"
        href="https://www.instagram.com/imacemconsultores/"
        type=""
      >
        <span>Instagram</span>
      </a>
      
      </p>
      
      </div>
      
      </div>
      
      </div>
      
      </div>
      
      <div id="ixz1b7" className="">
      
      <p className="" id="i4plpg">
        <span>IMACEM © 2024. Todos los derechos reservados.</span>
      </p>
      
      </div>
      
      </div>
      
      </div>
      
</div>
  );
}

const mapStateToProps = (state) => state.auth;

const mapDispatchToProps = (dispatch) => ({
 addLoftyTokenApp: (data) => dispatch(addLoftyToken(data)),
 deleteLoftytokenApp: () => dispatch(deleteLoftytoken()),
});


export default connect(mapStateToProps, mapDispatchToProps)(Pservicios2);
  