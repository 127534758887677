
import React, {useEffect, useState} from 'react';


import {connect} from 'react-redux';
import html2pdf from 'html2pdf.js';
import LoftyDropdownComponent from '../../components/loftydropdowncomponent';
import LoftyCountDown from '../../components/loftycountdown';
import LoftyCartCarousel from '../../components/loftycartcarousel';
import LoftyEditorJsReadOnly from '../../components/LoftyEditorJsReadOnly';
import LoftyEditorJs from '../../components/LoftyEditorJs';

import { addLoftyToken, deleteLoftytoken } from '../../actions/auth.actions';

import {customarticulosespañol_66902933c267708aecc01125 } from '../../api/articulosqdlkk.api'

import './paginablog.scss';


// Hamburger
function loftyInitHam() {
  try {
  // eslint-disable-next-line no-restricted-globals
    window.addEventListener('click', (event) => {
      let originPointLeft = '';
      const comp = event.target.className;
      if (comp.includes('menu__btn') || comp.includes('span_middle') || comp.includes('span_before') || comp.includes('span_after')) {
        const res = Array.from(document.getElementsByClassName('menu__box'));
        const spanData = Array.from(document.getElementsByClassName('menu__btn'));
        const closeBtn = Array.from(document.getElementsByClassName('menu__close__btn'));
        spanData.forEach((item) => {
          originPointLeft = item.style.left;
        });
        res.forEach((item) => {
          item.style.left = '0px';
        });
        spanData.forEach((item) => {
          item.style.left = '-300px';
        });
        closeBtn.forEach((item) => {
          item.style.left = '10px';
        });
      } else {
        if (comp === 'menu__box') {
          return;
        }
        if (comp === 'linkshref') {
          return;
        }
        if (comp === 'menu__item') {
          return;
        }
        const res = Array.from(document.getElementsByClassName('menu__box'));
        const spanData = Array.from(document.getElementsByClassName('menu__btn'));
        const closeBtn = Array.from(document.getElementsByClassName('menu__close__btn'));
        res.forEach((item) => {
          item.style.left = '-300px';
        });
        spanData.forEach((item) => {
          item.style.left = originPointLeft;
        });
        closeBtn.forEach((item) => {
          item.style.left = '-100%';
        });
      }
    });
  } catch (error) {
    // console.log(error);
  }
}    
    




const Ppaginablog = ({
  match,
  history,
  loftytoken,
  loftyuser,
  addLoftyUserApp,
  deleteLoftytokenApp,
  addLoftyTokenApp,
  
}) => {
	const [loftystate_busqueda, set_loftystate_busqueda] = useState()
	const [loftystate_inputtext, set_loftystate_inputtext] = useState()

	const [loftyres_66902933c267708aecc01125_component_ikv0od, setloftyres_66902933c267708aecc01125_component_ikv0od] = useState([]);

useEffect(() => {
		const loftyHandleData = async () => {
		}
		loftyHandleData();
	}, []);

	useEffect(() => {
		const handleLoftyData = async () => {
			try {
				const responseLoftyApps = await customarticulosespañol_66902933c267708aecc01125({
					busqueda: loftystate_busqueda,
				});
				setloftyres_66902933c267708aecc01125_component_ikv0od(responseLoftyApps.data);
			} catch (loftyerror) {
			}
		}
		handleLoftyData();
	}, [loftystate_busqueda, ]);


	function function_aplicandobusqueda2od43j_66853595914da0d427fe88d6() {
		set_loftystate_busqueda(loftystate_inputtext);
	}


  
  setTimeout(() => { loftyInitHam(); }, 2000);

  
  

  
  return (
    <div id="id66525124914da0d427fd6435" >

      <div id="i3cg" className="">
      
      <div id="iw4tpx" className="">
      
        <a
          target=""
          className=""
          id="i7xb8r"
          href="/"
        >
          
      <img
        className=""
        id="iw2km3"
        src="https://assetsprojects.s3.amazonaws.com/406racilu44h9vu.png"
        alt="undefined"
      />
      
      <img
        className=""
        id="ifnuqh"
        src="https://assetsprojects.s3.amazonaws.com/eewk5ilwi1t2ck.png"
        alt="undefined"
      />
      
        </a>
        
      <div id="i188q6" className="">
      
      <div id="" className="contentwrapperanimated ">
      
      <div id="" className="container_animated ">
      
      <label id="" className="menu__btn ">
      
      <span className="span_before " id="">
        
      </span>
      
      <span className="span_middle " id="">
        
      </span>
      
      <span className="span_after " id="">
        
      </span>
      
      </label>
      
      <label id="" className="menu__close__btn ">
      
      <span className="span__close__left " id="">
        
      </span>
      
      <span className="span__close__right " id="">
        
      </span>
      
      </label>
      
      <ul id="" className="menu__box ">
        
      <li className="linkshref " id="">
        
      <a
        target=""
        className="menu__item "
        id=""
        href="/"
        type=""
      >
        <span>Inicio</span>
      </a>
      
      </li>
      
      <li className="linkshref " id="">
        
      <a
        target=""
        className="menu__item "
        id=""
        href="/servicios"
        type=""
      >
        <span>Servicios</span>
      </a>
      
      </li>
      
      <li className="linkshref " id="">
        
      <a
        target=""
        className="menu__item "
        id=""
        href="/eventos"
        type=""
      >
        <span>Eventos</span>
      </a>
      
      </li>
      
      <li className="linkshref " id="">
        
      <a
        target=""
        className="menu__item "
        id=""
        href="/blog"
        type=""
      >
        <span>Blog</span>
      </a>
      
      </li>
      
      <li className="linkshref " id="">
        
      <a
        target=""
        className="menu__item "
        id=""
        href="/contacto"
        type=""
      >
        <span>Contacto</span>
      </a>
      
      <a
        target=""
        className="menu__item "
        id=""
        href="/en/blog"
        type=""
      >
        <span>Inglés</span>
      </a>
      
      </li>
      
      </ul>
      
      </div>
      
      </div>
      
      </div>
      
      <div id="i282zh" className="">
      
        <a
          target=""
          className="link-nabvar "
          id="igkctr"
          href={`/`}
        >
          
    <div id="im43t5" className="">
    <span>INICIO</span>
    </div>
    
        </a>
        
        <a
          target=""
          className="link-nabvar "
          id="iajvrv"
          href={`/servicios`}
        >
          
    <div id="i4h4c3" className="">
    <span>SERVICIOS</span>
    </div>
    
        </a>
        
        <a
          target=""
          className="link-nabvar "
          id="iibluu"
          href={`/eventos`}
        >
          
    <div id="io4byk" className="">
    <span>EVENTOS</span>
    </div>
    
        </a>
        
        <a
          target=""
          className="link-nabvar "
          id="iugyy5"
          href={`/blog`}
        >
          
    <div id="id5l3e" className="">
    <span>BLOG</span>
    </div>
    
        </a>
        
      </div>
      
      <div id="iucead" className="">
      
        <a
          target=""
          className="boton-lineal-azul "
          id="inoz1l"
          href={`/contacto`}
        >
          
    <div id="ii95xa" className="">
    <span>CONTACTAR</span>
    </div>
    
        </a>
        
        <a
          target=""
          className=""
          id="imdyke"
          href="/en/blog"
        >
          
      <img
        className=""
        id="idnkr7"
        src="https://assetsprojects.s3.amazonaws.com/eewk5ilwaojs7b.png"
        alt="undefined"
      />
      
        </a>
        
        <a
          target=""
          className=""
          id="io94k5"
          href="#"
        >
          
      <img
        className=""
        id="ixrczl"
        src="https://assetsprojects.s3.amazonaws.com/eewk5ilwaokuhj.png"
        alt="undefined"
      />
      
        </a>
        
      </div>
      
      </div>
      
      <div id="iz999t" className="">
      
      <div id="iwj18p" className="">
      
      <div
        id="izpzwf"
        className=""
        data-aos="fade-down"
        data-aos-duration="1000"
      >
      
      <span className="" id="i8qky5">
        <span>BLOG</span>
      </span>
      
      </div>
      
      </div>
      
      </div>
      
      <div id="izg5" className="">
      
      <div id="ijcy9b" className="">
      
      <div id="iif17w" className="">
      
      <div id="i0jv0l" className="tarjeta tarjeta-busqueda ">
      
      <div id="ihqfm2" className="">
      
        <div id="undefined" className="inputContainer ">
          
        <input
          name=""
          type="text"
          placeholder="Ingrese un titulo"
          className=""
          id="isfaby"
          onChange={(e) => {
            set_loftystate_inputtext(e.target.value);
          }}
          value={loftystate_inputtext}
        />
        
        </div>
      
      <button
        type=""
        name=""
        id="itdstb"
        className="boton-lineal-azul margenesbutton "
        onClick={async () => {
		function_aplicandobusqueda2od43j_66853595914da0d427fe88d6();
}}

      >
      
      <div id="ihg9wf" className="">
      <span>Buscar</span>
      </div>
      
      </button>
      
      </div>
      
      </div>
      
      </div>
      
      </div>
      
        <div id="ikv0od" className="">
        
            {
              loftyres_66902933c267708aecc01125_component_ikv0od.map((loftyitem_66902933c267708aecc01125_component_ikv0od) => (
                <div key={loftyitem_66902933c267708aecc01125_component_ikv0od._id} className="tarjeta-blog " id="iaxmcg">
                  
      <div id="i14zug" className="">
      
        <img
          className=""
          id="i4jypk"
          alt=""
          src={loftyitem_66902933c267708aecc01125_component_ikv0od.loftyUrl}
        />
        
      </div>
      
        <a
          target=""
          className=""
          id="i9bqfl"
          href={`/articulo/${loftyitem_66902933c267708aecc01125_component_ikv0od._id}`}
        >
          
        <div className="titulo-tarjeta " id="ieiqj9">
          
          <div className="" id="ic0c6">
            {loftyitem_66902933c267708aecc01125_component_ikv0od?.titulostjv1}
          </div>
          
        </div>
        
        </a>
        
      <div id="i1i8zc" className="">
      
        <div className="descripcion-tarjeta " id="idqxcn">
          
          <div className="" id="ibgo4o">
            {loftyitem_66902933c267708aecc01125_component_ikv0od?.descripcioncortabpxfi}
          </div>
          
        </div>
        
      </div>
      
                </div>
              ))
            }
            
        </div>
        
      </div>
      
      <div id="iat5x" className="">
      
      <div id="ighxck" className="">
      
      <img
        className=""
        id="inqivl"
        src="https://assetsprojects.s3.amazonaws.com/406racilu44h9vu.png"
        alt="undefined"
      />
      
      </div>
      
      <div id="ipa2qp" className="">
      
      <div id="ise2r9" className="">
      
      <div id="i0qriz" className="">
      
      <p className="" id="i14ubl">
        <span>Oficina</span>
      </p>
      
      <div id="i8hlod" className="">
      
      <p className="" id="icpwci">
        <span>Honduras - Tegucigalpa MDC</span>
      </p>
      
      <p className="" id="i0vnkg">
        
      <a
        target=""
        className=""
        id="inz53n"
        href="mailto:contact@imacempro.com"
        type=""
      >
        <span>contact@imacempro.com</span>
      </a>
      
      </p>
      
      </div>
      
      <p className="" id="i7jvdl">
        <span>+504 9982-3483</span>
      </p>
      
      </div>
      
      <div id="ia2by2" className="">
      
      <p className="" id="i5kwyk">
        <span>Links</span>
      </p>
      
      <div id="ijr81i" className="">
      
      <p className="" id="izt1ml">
        
      <a
        target=""
        className="vinculo-negro "
        id="i0dsjx"
        href="/"
        type=""
      >
        <span>Inicio</span>
      </a>
      
      </p>
      
      <p className="" id="ip0pg2">
        
      <a
        target=""
        className="vinculo-negro "
        id="i0qg4t"
        href="/eventos"
        type=""
      >
        <span>Eventos</span>
      </a>
      
      </p>
      
      <p className="" id="i4aa3m">
        
      <a
        target=""
        className="vinculo-negro "
        id="ie752i"
        href="/blog"
        type=""
      >
        <span>Blog</span>
      </a>
      
      </p>
      
      <p className="" id="iiv8pp">
        
      <a
        target=""
        className="vinculo-negro "
        id="ixb9qh"
        href="/contacto"
        type=""
      >
        <span>Contacto</span>
      </a>
      
      </p>
      
      </div>
      
      </div>
      
      <div id="ieb919" className="">
      
      <p className="" id="ic9ies">
        <span>Sociales</span>
      </p>
      
      <div id="idmvel" className="">
      
      <p className="" id="icjl6l">
        
      <a
        target="_blank"
        className=""
        id="ire1rf"
        href="https://www.linkedin.com/company/imacem-consultores-s-a/"
        type=""
      >
        <span>Linkedin</span>
      </a>
      
      </p>
      
      <p className="" id="io7ssq">
        
      <a
        target="_blank"
        className=""
        id="impr46"
        href="https://www.instagram.com/imacemconsultores/"
        type=""
      >
        <span>Instagram</span>
      </a>
      
      </p>
      
      </div>
      
      </div>
      
      </div>
      
      </div>
      
      <div id="ixz1b7" className="">
      
      <p className="" id="i4plpg">
        <span>IMACEM © 2024. Todos los derechos reservados.</span>
      </p>
      
      </div>
      
      </div>
      
      </div>
      
</div>
  );
}

const mapStateToProps = (state) => state.auth;

const mapDispatchToProps = (dispatch) => ({
 addLoftyTokenApp: (data) => dispatch(addLoftyToken(data)),
 deleteLoftytokenApp: () => dispatch(deleteLoftytoken()),
});


export default connect(mapStateToProps, mapDispatchToProps)(Ppaginablog);
  